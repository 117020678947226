import React, { Component } from "react";
import { HashRouter, Route } from "react-router-dom";
import Archiver from "./Archiver";
import "typeface-pt-sans";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#ffa733",
    },
    error: {
      main: "#ff2d00",
    },
    background: {
      main: "#eeeeee",
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "PT Sans",
  },
});

export default class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <HashRouter>
            <div>
              <Route path="/" exact component={Archiver} />
            </div>
          </HashRouter>
        </MuiThemeProvider>
      </div>
    );
  }
}
