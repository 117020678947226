import React, { Component } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import logo from "../res/archiver.png";
import base64url from "base64url";
import queryString from "query-string";
import Grid from "@material-ui/core/Grid/Grid";
import { withStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import authFetch from "../services/authFetch";

const styles = (theme) => ({
  blur: {
    filter: "grayscale(100%) blur(2px)",
    "-webkit-transition": "-webkit-filter 500ms linear",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  logo: {
    height: 40,
    width: 40,
    marginLeft: -12,
    marginRight: 20,
  },
  grow: {
    flexGrow: 1,
  },
  fatalWrapper: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 998,
  },
  fatal: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fatalPaper: {
    maxWidth: 650,
    minWidth: 350,
    textAlign: "center",
    padding: 10,
  },
  root: {
    position: "relative",
    flexGrow: 1,
    maxWidth: 1100,
    margin: "auto",
  },
  paper: {
    borderRadius: 0,
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  buttonWrapper: {
    display: "inline-block",
    margin: theme.spacing.unit,
    position: "relative",
  },
  errorWrapper: {
    display: "inline-block",
    margin: "auto",
    flexGrow: 1,
  },
  buttonProgress: {
    position: "absolute",
    // top: '50%',
    // left: '50%',
    // marginTop: 14,1
  },
  shake: {
    animation: "shake 200ms 2 linear",
  },
  "@keyframes shake": {
    "0%": {
      transform: "translate(6px, 0)",
    },
    "50%": {
      transform: "translate(-6px, 0)",
    },
    "100%": {
      transform: "translate(0, 0)",
    },
  },
});

class Archiver extends Component {
  addLoading = (key) => {
    let loadingComps = this.state.loadingComps;
    loadingComps[key] = true;
    this.setState({ loadingComps: loadingComps });
  };
  removeLoading = (key) => {
    let loadingComps = this.state.loadingComps;
    delete loadingComps[key];
    this.setState({ loadingComps: loadingComps });
  };
  isInitLoading = () => {
    const initLoadingStates = ["email", "elements-caseworker-info"];
    let loadingComps = this.state.loadingComps;

    for (let i = 0; i < initLoadingStates.length; i++) {
      if (loadingComps[initLoadingStates[i]]) {
        return true;
      }
    }
    return false;
  };
  isLoading = () => Object.keys(this.state.loadingComps).length > 0;
  addDisableNext = (key) => {
    let disableComps = this.state.disableComps;
    disableComps[key] = true;
    this.setState({ disableComps: disableComps });
  };
  removeDisableNext = (key) => {
    let disableComps = this.state.disableComps;
    delete disableComps[key];
    this.setState({ disableComps: disableComps });
  };

  isNextDisabled = () => Object.keys(this.state.disableComps).length > 0;

  handleNext = async () => {
    this.addLoading("next-pressed");
    this.updateStore({ buttonText: "" });
    this.updateStore({ hideSupport: false });
    this.updateStore({ elementsErrorMsg: null });
    this.setState({ error: "" });

    const { activeStep } = this.state;

    let errorMsg = "";

    if (this.child.validate) {
      // check if child has validate-func
      errorMsg = await this.child.validate.bind(this.child)();
    }

    if (!errorMsg) {
      this.setState({ error: null });

      this.updateStore(this.child.state);
      this.setState({
        activeStep: activeStep + 1,
        stepComponent: this.getStepComponent(activeStep + 1),
        disableComps: {},
      });
      console.debug("Validation successful");
    } else {
      this.setState({ error: errorMsg });
    }

    this.removeLoading("next-pressed");
  };
  getStore = () => {
    return this.state.store;
  };
  updateStore = (update) => {
    this.setState({
      store: {
        ...this.state.store,
        ...update,
      },
    });
  };
  handleBack = () => {
    const { activeStep } = this.state;
    this.updateStore(this.child.state);
    let stepDiff = 1;
    if (
      this.state.store.chosenStorageOption === "quick-jp" &&
      this.state.activeStep === 4
    ) {
      stepDiff = 2;
    }
    this.setState({
      activeStep: activeStep - stepDiff,
      disableComps: {},
      errorMsg: "",
    });
  };
  handleReset = () => {
    window.close();
  };
  renderSuccessfulArchiving = () => {
    const { classes } = this.props;

    let caseTitle = `${this.state.store.case.saksnr.saksaar}/${
      this.state.store.case.saksnr.sakssekvensnummer
    }`;
    let jpNumber = this.state.store.selectedJpNr;
    return [
      <Typography
        className={classes.instructions}
        key="success"
        style={{ marginBottom: "10px", fontSize: "22px" }}
      >
        <b>
          Vellykket arkivering: {caseTitle}-{jpNumber}
        </b>
      </Typography>,
      <Typography
        className={classes.instructions}
        key="archived"
        style={{ marginBottom: "10px" }}
      >
        E-posten er arkivert i TKsak under sak {caseTitle} med journalpost-ID:{" "}
        {jpNumber}.
      </Typography>,
      <Typography
        className={classes.instructions}
        key="continuation"
        style={{ marginBottom: "10px" }}
      >
        <b>Kontroll, eventuell skjerming, og ferdigstilling må gjøres i TKsak.</b>
      </Typography>,
      <img
        src="https://storage.googleapis.com/gmailgadget.trondheim.kommune.no/gmailaddon/check-noloop-cropped.gif"
        key="archiveSuccessGif"
        alt="archiveSuccess"
        width="150px"
      />,
    ];
  };
  runPreamble = () => {
    // if (this.getStore().emailBody == null) { // Only load data from API if not already fetched
    // $("#next-button").hide();

    const msgId = this.getStore().messageId;
    const senderAndRecipients = this.getStore().senderAndRecipients;

    if (msgId == null || msgId.trim() === "") {
      this.setFatal("Mangler url-parameter msgid");
    } else if (
      senderAndRecipients == null ||
      senderAndRecipients.trim() === ""
    ) {
      this.setFatal("Mangler url-parameter senderAndRecipients");
    } else {
      // Get message metadata from Gmail API
      this.addLoading("email");
      this.getEmail(msgId, senderAndRecipients)
        .then(() => {
          console.debug("fetching elements caseworker data...");
          this.addLoading("elements-caseworker-info");
          this.getElementsCaseworkerInfo().finally(() => {
            this.removeLoading("elements-caseworker-info");

            const { isUserInADGroup, isUserFoundInElements } = this.state.store.elementsCaseworkerInfo;
            
            if (!isUserInADGroup || !isUserFoundInElements) {
              // Disable the next button if the user is not in the AD group that gives access to Elements, or if the user was not found in Elements
              this.addDisableNext("no-access-to-elements");
            }

            this.setState({ isInitFetchDone: true });
          });
        })
        .finally(() => {
          this.removeLoading("email");
        });
    }
  };

  // TODO set authenticatedUserEmail from /gap/aboutMe response

  getElementsCaseworkerInfo = () => {
    return authFetch("/elements/caseworker")
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }
        return res.json();
      })
      .then((respBody) => {
        console.debug("Elements caseworker info fetched:", respBody);

        this.updateStore({
          elementsCaseworkerInfo: respBody,
        });
      })
      .catch((err) => this.setFatal(err.toString()));
  };
  getEmail = (msgId, senderAndRecipients) => {
    let url = `/gmail/msgandattach/${msgId}?senderAndRecipients=${senderAndRecipients}&getFileAttachmentsCode=2`;
    return authFetch(url)
      .then((res) => {
        if (!res.ok) {
          throw res.statusText;
        }
        return res.json();
      })
      .then((body) => {
        console.debug("email fetched:", body);

        let anyImages = false;
        body.attachments
          .filter((attachment) => attachment.mimetype.indexOf("image") >= 0)
          .forEach((attachment) => {
            attachment.bodyB64 = base64url.toBase64(attachment.body);
            anyImages = true;
          });

        this.updateStore({ email: body, anyImages: anyImages });
        this.child.setEmail(body);
      })
      .catch((err) => this.setFatal(err.toString()));
  };

  constructor(args) {
    super(args);

    let t = this;

    let params = queryString.parse(this.props.location.search);

    this.state = {
      isAuthenticated: false,
      authenticationError: "",
      loadingComps: {},
      disableComps: {},
      isInitFetchDone: false,
      store: {
        messageId: params.msgid,
        senderAndRecipients: params.senderAndRecipients,
        elementsCaseworkerInfo: {
          elementsUsername: undefined,
          isUserFoundInElements: false,
          isUserInADGroup: false,
        },
        newJp: true, // default value
        caseYear: undefined,
        caseSequenceNumber: undefined,
      },
      activeStep: 0,
      stepComponent: null,
      shake: false,
    };

    this.steps = [
      {
        name: "Innlogging",
        component: (
          <Step1
            addLoading={this.addLoading}
            removeLoading={this.removeLoading}
            loadingComps={this.state.loadingComps}
            getStore={this.getStore.bind(this)}
            updateStore={this.updateStore}
            shake={this.shake.bind(this)}
            onRef={(ref) => (t.child = ref)}
            goNext={this.onEnterGoNext.bind(this)}
            setFatal={this.setFatal.bind(this)}
          />
        ),
      },
      {
        name: "Sak",
        component: (
          <Step2
            addLoading={this.addLoading}
            removeLoading={this.removeLoading}
            getStore={this.getStore.bind(this)}
            updateStore={this.updateStore}
            onRef={(ref) => (t.child = ref)}
            goNext={this.onEnterGoNext.bind(this)}
          />
        ),
      },
      {
        name: "Journalpost",
        component: (
          <Step3
            addLoading={this.addLoading}
            removeLoading={this.removeLoading}
            getStore={this.getStore.bind(this)}
            updateStore={this.updateStore}
            onRef={(ref) => (t.child = ref)}
            addDisableNext={this.addDisableNext}
            removeDisableNext={this.removeDisableNext}
            setFatal={this.setFatal.bind(this)}
          />
        ),
      },
      {
        name: "Vedlegg",
        component: (
          <Step4
            addLoading={this.addLoading}
            removeLoading={this.removeLoading}
            getStore={this.getStore.bind(this)}
            updateStore={this.updateStore}
            handleNext={this.handleNext.bind(this)}
            onRef={(ref) => (t.child = ref)}
          />
        ),
      },
      {
        name: "Oppsummering",
        component: (
          <Step5
            addLoading={this.addLoading}
            removeLoading={this.removeLoading}
            getStore={this.getStore.bind(this)}
            updateStore={this.updateStore}
            onRef={(ref) => (t.child = ref)}
          />
        ),
      },
      {
        name: "Arkivering",
        component: (
          <Step6
            addLoading={this.addLoading}
            removeLoading={this.removeLoading}
            getStore={this.getStore.bind(this)}
            updateStore={this.updateStore}
            handleNext={this.handleNext.bind(this)}
            onRef={(ref) => (t.child = ref)}
          />
        ),
      },
    ];
  }

  componentDidMount() {
    authFetch("/verify")
      .then(async (res) => {
        if (res.ok) {
          const jsonRes = await res.json();
          this.setState({ isAuthenticated: jsonRes.isAuthenticated });
        } else if (res.status === 403) {
          this.setState({ authenticationError: "forbidden" });
        } else {
          this.setState({
            authenticationError: `Ukjent respons fra /auth: ${res.statusText}`,
          });
        }
      })
      .catch((e) => {
        this.setState({
          authenticationError: `Ukjent feil oppsto under autorisering: ${e}`,
        });
      });
    this.runPreamble();
  }

  getStepComponent(index) {
    return this.steps[index];
  }

  setFatal(msg) {
    this.setState({ fatal: msg });
  }

  shake() {
    this.setState({ shake: true });
    setTimeout(() => this.setState({ shake: false }), 200);
  }

  onEnterGoNext(e) {
    if (e.key === "Enter") {
      this.handleNext.bind(this)();
    }
  }

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;
    let t = this;
    return (
      <div className={classes.root}>
        {this.state.fatal ? (
          <div className={classes.fatalWrapper}>
            <Grow
              in={Boolean(this.state.fatal)}
              {...(this.state.fatal ? { timeout: 500 } : {})}
            >
              <div className={classes.fatal}>
                <Paper className={classes.fatalPaper}>
                  <Typography variant="subtitle1" color="error">
                    {this.state.fatal}
                  </Typography>
                  <Typography variant="subtitle1" color="error">
                    Lukk denne siden og prøv på nytt
                  </Typography>
                </Paper>
              </div>
            </Grow>
          </div>
        ) : null}
        <AppBar
          position={"static"}
          className={this.state.fatal ? classes.blur : null}
        >
          <Toolbar>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Typography variant="h6" color={"inherit"} className={classes.grow}>
              Gmail-arkivering - {process.env.REACT_APP_VERSION}
            </Typography>
            <Typography variant="h6" color={"inherit"}>
              {/*this.state.authenticatedUserEmail TODO fix*/}
            </Typography>
            <IconButton
              color="inherit"
              href="https://docs.google.com/document/d/1jJuC7V1t7JQQIvL4VXxnj2ftMdW4YgG5SjeIWwlx5Vo/edit?usp=sharing"
              target="_blank"
            >
              <HelpIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Paper
          className={[
            classes.paper,
            this.state.fatal ? classes.blur : null,
          ].join(" ")}
        >
          <Stepper activeStep={activeStep}>
            {this.steps.map((step) => {
              return (
                <Step key={step.name}>
                  <StepLabel>{step.name}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === this.steps.length ? (
              <div>
                <Grid container justify="center">
                  <div style={{ textAlign: "center" }}>
                    {this.renderSuccessfulArchiving()}
                  </div>
                </Grid>
                <Button onClick={this.handleReset} className={classes.button}>
                  Lukk
                </Button>
              </div>
            ) : (
              <div>
                <Typography variant="h5">
                  {this.steps[activeStep].name}
                </Typography>
                <div
                  key={this.isInitLoading() ? "initLoading" : "initLoadingDone"} // This is a hack to force a re-render of the child components when loading is done
                  className={this.state.shake ? classes.shake : null}
                >
                  {this.steps[activeStep].component}
                </div>
                <div className={classes.flexContainer}>
                  <div className={classes.buttonWrapper}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={t.handleBack}
                      className={classes.button}
                    >
                      Tilbake
                    </Button>
                  </div>
                  <div className={classes.buttonWrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                      disabled={this.isLoading() || this.isNextDisabled()}
                    >
                      {this.state.store.buttonText
                        ? this.state.store.buttonText
                        : activeStep === this.steps.length
                          ? "Avslutt"
                          : "Neste"}
                      {this.isLoading() ? (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      ) : null}
                    </Button>
                  </div>
                  {this.state.error ? (
                    <Typography
                      className={classes.errorWrapper}
                      color={"error"}
                    >
                      {this.state.error}
                      {!this.state.store.hideSupport ? (
                        <span key="contact-support">
                          . Hvis problemet vedvarer, meld inn sak via
                          Selvbetjeningsportalen.
                        </span>
                      ) : null}
                      {this.state.store.elementsErrorMsg ? (
                        <span key="elements-error">
                          <br />
                          {`Feilmelding: ${this.state.store.elementsErrorMsg}`}
                        </span>
                      ) : null}
                    </Typography>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Archiver);
