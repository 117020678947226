import calculateFetchPath from "./calculateFetchPath";

export default async function authFetch(url, params) {
  let fetchParams = params;
  if (!fetchParams) {
    fetchParams = { headers: {} };
  }
  fetchParams = {
    ...fetchParams,
    headers: {
      ...fetchParams.headers,
    },
  };

  url = "/gap" + url;

  const res = await fetch(url, fetchParams);

  console.log("res", res);
  if (res.status === 401) {
    const refreshRes = await fetch(calculateFetchPath(`/gap/verify`));

    if (refreshRes.status !== 200) {
      window.location =
        `${window.location.origin + calculateFetchPath(`/gap/redirect`)}?redirect-uri=${encodeURIComponent(window.location.href) || "/"}`;
      return null;
    }

    const secondAttemptRes = await fetch(url, fetchParams);
    if (secondAttemptRes.status === 401) {
      throw new Error(`fetch ${url} returned 401 right after refresh`);
    }

    return secondAttemptRes;
  }

  return res;
}
