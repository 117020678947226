

// Checks if an email address is valid
export function isValidEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}


// Returns a string with user's full name, last name, or only email address, depending on what person data is available
export function getNameOrEmailStr(personData) {
    const hasFirstName = (personData.firstName != null && personData.firstName.trim() !== "");
    const hasLastName = (personData.lastName != null && personData.lastName.trim() !== "");

    if (hasFirstName && hasLastName) {
        return personData.firstName + " " + personData.lastName;
    } else if (hasLastName) {
        return personData.lastName;
    } else {
        return personData.email;
    }
}


// Same as getNameOrEmailStr function, but with html encoding
export function getNameAndEmailHtml(personData) {
    if (personData.email == null) return "";

    const hasFirstName = (personData.firstName != null && personData.firstName.trim() !== "");
    const hasLastName = (personData.surname != null && personData.surname.trim() !== "");

    const mailtoLink = generateMailtoLink(personData.email);

    if (hasFirstName && hasLastName) {
        return personData.firstName + " " + personData.surname + " &lt;" + mailtoLink + "&gt;";
    } else if (hasLastName) {
        return personData.surname + " &lt;" + mailtoLink + "&gt;";
    } else {
        return mailtoLink;
    }
}


export function generateMailtoLink(email) {
    return "<a href='mailto:" + email + "' target='_blank'>" + email + "</a>";
}
