import React, {Component} from "react";
import TextField from "@material-ui/core/TextField/TextField";
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";

const styles = theme => ({
    root: {
        display: 'flex'
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
});

function caseNumInvalid(num) {
    return !num || num === "" || num < 0 || num > 100000000000;
}

class Step2 extends Component {

    onChange = (event) => {
        let newState = {};
        newState[event.target.name] = event.target.value;
        newState[event.target.name + "Changed"] = true;
        console.debug("newState", newState);
        this.setState(newState);
        this.props.updateStore(newState);
    };

    constructor(props) {
        super(props);
        this.state = props.getStore();
        // this.state.caseSequenceNumber = undefined;
        // this.state.caseYear = undefined;
        // this.goNext = props.goNext;
        console.debug("CONSTRUCTOR CALLED STEP 2")
    }

    componentDidMount() {
        this.props.onRef(this);
        this.setState({
            errorMsg: "",
        });
        this.setState(this.props.getStore());
    }

    async validate() {
        let s = this.state;

        if (caseNumInvalid(s.caseYear) || caseNumInvalid(s.caseSequenceNumber)) {
            this.setState({
                caseYearChanged: true,
                caseSequenceNumberChanged: true
            });
            this.props.updateStore({hideSupport: true});
            return "Feltene må ha gyldige tallverdier";
        }

        let caseID = s.caseYear + "/" + s.caseSequenceNumber;
        if (this.state.lastFetchedCaseID && this.state.lastFetchedCaseID === caseID) {
            console.debug(`Skipping case fetching, ${caseID} already fetched`);
            return "";
        }

        this.props.addLoading("sak-fetch");

        let year = s.caseYear.toString();

        if (year.length === 1) {
            year = "0" + year
        }

        return await fetch(`/gap/elements/sak/${year}/${s.caseSequenceNumber}`,
        ).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                throw res.statusText
            }
        }).then(body => {
            let elementsSak = body;
            if (elementsSak.saksstatus === "U" || elementsSak.saksstatus === "A") {
                return `Kan ikke arkivere til denne saken pga. dens status: ${elementsSak.saksstatus}. Velg en annen sak`;
            }
            elementsSak.saksnr = {
                saksaar: parseInt(s.caseYear),
                sakssekvensnummer: parseInt(s.caseSequenceNumber),
            }
            this.setState({case: elementsSak});
            this.setState({lastFetchedCaseID: caseID});
            return '';
        }).catch(error => {
            let caseNum = s.caseYear + "/" + s.caseSequenceNumber;
            console.error("Unexpected error occurred while requesting Elements case: " + caseNum, error);
            return "En feil oppsto under søk etter sak i Elements med saksnummer " + caseNum;
        }).finally(() => {
            this.props.removeLoading("sak-fetch");
        });
    }

    render() {
        const {classes} = this.props;

        return (
            <form className={classes.container} noValidate autoComplete="off">
                <Grid container justify="center">
                    <div>
                        <TextField
                            autoFocus={true}
                            type="number"
                            name="caseYear"
                            label="Sak-år"
                            placeholder="Sak-år"
                            className={classes.textField}
                            value={this.state.caseYear ? this.state.caseYear : ""}
                            onChange={this.onChange}
                            margin="normal"
                            error={this.state.caseYearChanged && caseNumInvalid(this.state.caseYear)}
                            inputProps={{min: "1"}}
                            onKeyPress={this.props.goNext}
                        />
                    </div>
                    <div>
                        <TextField
                            type="number"
                            name="caseSequenceNumber"
                            label="Sak-sekvensnummer"
                            placeholder="Sak-sekvensnummer"
                            className={classes.textField}
                            onChange={this.onChange}
                            value={this.state.caseSequenceNumber ? this.state.caseSequenceNumber : ""}
                            margin="normal"
                            error={this.state.caseSequenceNumberChanged && caseNumInvalid(this.state.caseSequenceNumber)}
                            inputProps={{min: "1"}}
                            onKeyPress={this.props.goNext}
                        />
                    </div>
                </Grid>
                <div style={{color: "red"}}>{this.state.errorMsg}</div>
            </form>
        )
    }
}

export default withStyles(styles)(Step2);