import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import {withStyles} from "@material-ui/core";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid/Grid";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import Tooltip from '@material-ui/core/Tooltip';
import Image from '@material-ui/icons/Image';
import Typography from "@material-ui/core/Typography/Typography";
import base64url from "base64url";


const sanitizeFilenameRegex = new RegExp('([/?*:<>|"\'])', 'g');

const styles = theme => ({
    root: {
        display: 'flex'
    },
    formControl: {
        margin: theme.spacing.unit * 3,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    table: {
        minWidth: 700,
    },
    attachmentFileNameInput: {
        width: '100%',
    },
    imagePreview: {
        maxWidth: 300,
        height: "auto"
    },
    lightTooltip: {
        boxShadow: theme.shadows[1],
        border: 'solid #222 1px',
        opacity: 1,
        filter: 'alpha(opacity=100)',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        rippleBackgroundColor: 'rgba(255, 255, 255, 1)'
    }
});

const blockedFileExtensions = ["bat", "exe", "bin", "cmd", "ps1"];

// Checks the file extension against a blacklist of file extensions that should not be sent to TK-sak
function fileExtBlocked(fileExtension) {
    // check if given extensions is in the list of blocked extensions. Blocked if true
    return blockedFileExtensions.indexOf(fileExtension.toLowerCase()) >= 0;
}

class Step4 extends Component {
    constructor(props) {
        super(props);
        this.state = props.getStore();

        if (this.state.attachmentsPayload == null) {
            let emailBody = (this.state.email.body != null) ? this.state.email.body.bodyBase64 : null;

            let atcPayload = this.createAttachmentsMap(
                this.state.email.subject,
                emailBody,
                this.state.email.attachments
            );

            this.state.attachmentsPayload = atcPayload;
            console.debug(Object.keys(atcPayload).length, "<", this.state.email.attachments.length + 1);
            let emptyBody = (this.state.email.body == null) ? 1 : 0;
            this.state.filesHaveBeenBlocked = Object.keys(atcPayload).length + emptyBody < this.state.email.attachments.length + 1;

            this.state.containsGoogleDriveLink = false;
            if (emailBody && emailBody !== "") {
                let emailBodyDecoded = base64url.decode(emailBody);
                this.state.containsGoogleDriveLink = emailBodyDecoded.indexOf("<a href=\"https://drive.google.com/a/") >= 0;
            }
        }

        if (this.state.chosenStorageOption === "existing-jp") {
            this.state.mainDocId = null;
        } else if (this.state.mainDocId == null) {

            // Default value for main doc dropdown
            if (this.state.email.body != null) {
                this.state.mainDocId = "emailBody";
            } else {
                this.state.mainDocId = this.state.email.attachments[0].id;
            }


        }

        if (this.state.writeMetadata == null) {
            this.state.writeMetadata = true; // Default value for writeMetadata checkbox
        }

        // Go directly to step5 if quick archive is selected as storageOption
        if (this.state.chosenStorageOption === "quick-jp") {
            this.props.handleNext();
        }
    }

    createAttachmentsMap = (emailSubject, emailBody, fileAttachments) => {
        let attachmentsPayload = {};

        if (emailBody != null) {
            let filenameEmailBody = Step4.sanitizeFilename(emailSubject);
            if (filenameEmailBody === "") filenameEmailBody = "(ikke noe emne)";

            attachmentsPayload["emailBody"] = {
                "id": "emailBody",
                "selected": true,
                "originalFilename": emailSubject,
                "filename": filenameEmailBody, // default value, can be changed in text input field
                "fileExtension": "html",
                "isEmailBody": true,
                "isImportable": true,
                "status": "F", // default value, can be changed in status dropdown
                "mimeType": "text/html",
                "body": emailBody
            };
        }

        fileAttachments.forEach(fileAttachment => {
            let isImportable = true;
            let filenameWithType = fileAttachment.filename;
            let filename = filenameWithType;
            let fileExtension = "";

            let indexOfPeriod = filenameWithType.lastIndexOf(".");
            if (indexOfPeriod <= 0 || indexOfPeriod === filenameWithType.length - 1) {
                isImportable = false; // Files without a file type are not importable
            } else {
                filename = filenameWithType.substring(0, indexOfPeriod);
                fileExtension = filenameWithType.substring(indexOfPeriod + 1);
                isImportable = !fileExtBlocked(fileExtension);
            }
            console.debug("filename:", filename, "fileExtension:", fileExtension, "isImportable:", isImportable);
            if (!isImportable) {
                return
            }

            filename = Step4.sanitizeFilename(filename);
            if (filename === "") filename = "(uten filnavn)";

            attachmentsPayload[fileAttachment.id] = {
                "id": fileAttachment.id,
                "selected": isImportable,
                "originalFilename": filename,
                "filename": filename, // default value, can be changed in text input field
                "fileExtension": fileExtension,
                "isEmailBody": false,
                "isImportable": isImportable,
                "status": "F", // default value, can be changed in status dropdown
                "size": fileAttachment.size,
                "mimeType": fileAttachment.mimetype,
                "body": fileAttachment.body,
                "bodyB64": fileAttachment.bodyB64
            }
        });

        return attachmentsPayload;
    };

    // Removes invalid characters from a filename
    static sanitizeFilename(filename) {
        if (filename == null) return "";
        filename = filename.substring(0, 200); // Max length is 200 chars
        filename = filename.replace(sanitizeFilenameRegex, '');
        filename = filename.replace('\\', '');

        filename = filename.trim();
        return filename;
    }

    handleMainDocChange() {
        return (event) => {
            this.setState({mainDocId: event.target.value});
        }
    }

    handleWriteMetadataChange() {
        return (event) => {
            this.setState({writeMetadata: event.target.checked});
        }
    }

    handleAttachmentCheckboxChange(attachmentId) {
        return (event) => {
            let attachments = {...this.state.attachmentsPayload};
            let newValChecked = event.target.checked;
            let canSetNewValue = true;

            if (!newValChecked) {
                // Need to verify that the last checkbox is not deselected.
                // There must be at least one attachment selected at all times.
                let selectedCounter = 0;
                Object.keys(attachments).forEach(attachmentKey => {
                    if (attachments[attachmentKey].selected) selectedCounter++;
                });
                if (selectedCounter < 2) {
                    canSetNewValue = false;
                } else {
                    // If unchecked attachment was main doc, set next selected attachment as main doc
                    if (attachmentId === this.state.mainDocId) {
                        for (let attachmentKey in attachments) {
                            if (attachmentKey !== attachmentId && attachments[attachmentKey].selected) {
                                this.setState({mainDocId: attachments[attachmentKey].id});
                                break;
                            }
                        }
                    }
                }
            }

            if (canSetNewValue) {
                attachments[attachmentId].selected = newValChecked;
                this.setState({attachmentsPayload: attachments});
            }
        }
    }

    handleAttachmentSettingInput(attachmentId, attachmentFieldName) {
        return (event) => {
            let payload = {...this.state.attachmentsPayload};
            payload[attachmentId][attachmentFieldName] = event.target.value;
            this.setState({attachmentsPayload: payload});
        }
    }

    handleAttachmentFilenameBlur(attachmentId) {
        return (event) => {
            let payload = {...this.state.attachmentsPayload};
            let sanitizedFilename = Step4.sanitizeFilename(event.target.value);
            payload[attachmentId].filename = sanitizedFilename;
            this.setState({attachmentsPayload: payload});
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
    }

    validate = async () => {
        let retVals = [];
        let attachments = this.state.attachmentsPayload;

        for (let attachmentKey in attachments) {
            let attachment = attachments[attachmentKey];

            if (attachment.selected == null) {
                retVals.push("Uventet feil: kunne ikke finne selected-verdi for vedlegg " + attachment.id);
            } else if (attachment.selected) {
                if (attachment.filename.trim() === "") {
                    retVals.push("Filnavn er påkrevd for alle vedlegg som skal arkiveres.");
                    this.props.updateStore({hideSupport: true});
                }
                if (attachment.status !== "F" && attachment.status !== "B") {
                    retVals.push("Uventet feil: kunne ikke finne status-verdi for vedlegg " + attachment.id);
                }
            }
        }
        return retVals.join(". ");
    };

    render() {
        const {classes} = this.props;
        return (
            <form className={classes.container} noValidate autoComplete="off">
                <Grid container className={classes.container} spacing={40} style={{margin: "30px 0"}}>
                    <Grid item container key="mainDoc" xs={6} sm={6} className={classes.flexContainer}>
                        <FormControl component="fieldset">
                            {this.renderMainDocDropdown()}
                        </FormControl>
                    </Grid>
                    <Grid item container key="writeMetadata" xs={6} sm={6} className={classes.flexContainer}>
                        {this.renderWriteMetadataOption()}
                    </Grid>
                </Grid>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Arkivér</TableCell>
                            {this.state.anyImages ? (<TableCell>Vis</TableCell>) : null}
                            <TableCell>Filnavn</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.renderAttachmentList()}
                </Table>
                {this.state.filesHaveBeenBlocked ? (
                    <div>
                        <Typography color={"error"}>
                            Én eller flere vedlegg har blitt gjemt grunnet at de er av en blokkert filtype
                        </Typography>
                        <Typography color={"error"}>
                            De blokkerte filtypene er: {blockedFileExtensions.join(", ")}
                        </Typography>
                    </div>
                ) : null}
            </form>
        )
    }

    renderMainDocDropdown() {
        let attachments = this.state.attachmentsPayload;

        if (this.state.chosenStorageOption !== "existing-jp") {
            return (
                [
                    <FormLabel key="mainDocLabel" component="legend">Hoveddokument</FormLabel>,
                    <Select key="mainDocSelect"
                            value={this.state.mainDocId}
                            label="Hoveddokument"
                            onChange={this.handleMainDocChange()}
                    >
                        {Object.keys(attachments).map(attachmentKey => {
                            let attachment = attachments[attachmentKey];
                            if (attachment.selected) {
                                return (
                                    <MenuItem key={attachmentKey}
                                              value={attachmentKey}>{attachment.filename + "." + attachment.fileExtension}</MenuItem>
                                );
                            }
                            return null;
                        })}
                    </Select>
                ]
            );
        }

    }

    renderWriteMetadataOption() {
        let attachments = this.state.attachmentsPayload;

        if (attachments["emailBody"] != null) {
            return (
                <Tooltip
                    title={"Skriv metadata (fra, til, dato etc.) øverst i dok. for epost-innhold (" +
                    attachments["emailBody"].filename + ".html). Fjern hvis du har videresendt til deg selv."}
                    placement="top"
                >
                    <FormControl
                        component="fieldset"
                        style={{visibility: attachments["emailBody"].selected ? 'visible' : 'hidden'}}
                    >
                        <FormLabel component="legend">Skriv metadata</FormLabel>
                        <Checkbox
                            checked={this.state.writeMetadata}
                            value="writeMetadataCheckbox"
                            onChange={this.handleWriteMetadataChange()}
                        />
                    </FormControl>
                </Tooltip>
            );
        }
    }

    renderAttachmentList() {
        const {classes} = this.props;
        let attachments = this.state.attachmentsPayload;

        return (
            <TableBody>
                {Object.keys(attachments).map(attachmentKey => {
                    let attachment = attachments[attachmentKey];
                    return (
                        <TableRow key={"attachmentRow-" + attachmentKey}>
                            <TableCell>
                                <Checkbox
                                    checked={attachment.selected}
                                    value={attachmentKey}
                                    onChange={this.handleAttachmentCheckboxChange(attachment.id)}
                                />
                            </TableCell>
                            {this.state.anyImages ? (
                                <TableCell>
                                    {attachment.mimeType.indexOf("image") >= 0 ? (
                                        <Tooltip
                                            style={{opacity: '1'}}
                                            classes={{ tooltip: classes.lightTooltip }}
                                            title={
                                            <img alt="imgPreview" className={classes.imagePreview}
                                                 src={"data:" + attachment.mimeType + ";base64, " + attachment.bodyB64}/>
                                        }>
                                            <Image/>
                                        </Tooltip>
                                    ) : null}
                                </TableCell>
                            ) : null}
                            <TableCell>
                                <Input
                                    className={classes.attachmentFileNameInput}
                                    value={attachment.filename}
                                    endAdornment={<InputAdornment
                                        position="end">{"." + attachment.fileExtension}</InputAdornment>}
                                    onChange={this.handleAttachmentSettingInput(attachment.id, "filename")}
                                    onBlur={this.handleAttachmentFilenameBlur(attachment.id)}
                                    disabled={!attachment.selected}
                                    error={attachment.filename.trim() === "" && attachment.selected}
                                    placeholder={attachment.filename.trim() === "" && attachment.selected ? "Skriv inn et filnavn" : ""}
                                />
                            </TableCell>
                        </TableRow>
                    )
                })}
                {this.state.containsGoogleDriveLink ? (
                    <TableRow>
                        <TableCell colSpan={4} style={{margin: "10px 0", color: "red"}}>
                            <strong>OBS:</strong> E-posten inneholder kobling til en eller flere filer i Google Disk,
                            men det er p.t. ikke mulig å arkivere disse via denne arkivløsningen.<br/>
                            Disse kan arkiveres ved å laste ned til din PC og deretter laste dem opp igjen direkte i TK-sak.
                        </TableCell>
                    </TableRow>
                ) : null}
            </TableBody>
        );
    }
}

export default withStyles(styles)(Step4);