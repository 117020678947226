import React, {Component} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel'
import Paper from '@material-ui/core/Paper';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails'
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'

const styles = theme => ({
	expWrap: {
		margin: 35,
		width: '100%'
	},
	group: {
		margin: `${theme.spacing.unit}px 0`,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 200,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	elementsArchivingPaper: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
		margin: 35,
		backgroundColor: '#e5f0f6',
	},
	elementsArchivingPaperError: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit * 2,
		margin: 35,
		backgroundColor: '#f6e5e5',
	},
	elementsArchivingPaperParagraph: {
		padding: '.3rem 0',
	}
})

const contactParse = contact => contact.name
	? `${contact.name.replace(/"/g, '')} <${contact.email}>`
	: contact.email

const contactListParse = contacts => contacts
	? contacts.map(contactParse).join(', ')
	: null

const emailParsers = [
	{key: 'subject', name: 'Emne'},
	{key: 'snippet', name: 'Utdrag fra body-tekst'},
	{key: 'from', name: 'Fra', parse: contactParse},
	{key: 'to', name: 'Til', parse: contactListParse},
	{key: 'cc', name: 'Kopimottakere', parse: contactListParse},
	{key: 'bcc', name: 'Blindkopimottakere', parse: contactListParse},
]

class Step1 extends Component {
	constructor(props) {
		super(props)
		this.state = props.getStore()
		this.goNext = props.goNext
	}

	componentDidMount() {
		this.props.onRef(this)

        this.setState({
            errorMsg: '',
        });
	}

    setEmail = (email) => {
    	this.setState({email: email})
    };

    render() {
		const initLoadingStates = ['email', 'elements-caseworker-info'];
		if (Object.keys(this.props.loadingComps).length > 0) {
			
			// Only show the loading indicator if we're fetching initial data
			if (initLoadingStates.some(state => this.props.loadingComps[state])) {
				return (
					<div style={{padding: '2rem 0'}}>
						<LinearProgress />
					</div>
				)
			}
		};

		const {
			isUserInADGroup,
			isUserFoundInElements,
			elementsUsername,
		  } = this.state.elementsCaseworkerInfo;

    	const {classes} = this.props
    	return (
    		<form className={classes.container} noValidate autoComplete="off">
    			


				{!isUserInADGroup && (
					<Paper className={classes.elementsArchivingPaperError} elevation={1}>
						<Typography variant="h6" component="h3">
							Kan ikke arkiveres til Elements
						</Typography>
						<Typography className={classes.elementsArchivingPaperParagraph}>
							Du er ikke med i AD-gruppen (Active Directory) som gir tilgang til Elements.
						</Typography>
						<Typography className={classes.elementsArchivingPaperParagraph}>
							For å kunne arkivere til Elements, må det bestilles tilgang.
						</Typography>
					</Paper>
				)}
				{isUserInADGroup && !isUserFoundInElements && (
					<Paper className={classes.elementsArchivingPaperError} elevation={1}>
						<Typography variant="h6" component="h3">
							Kan ikke arkiveres til Elements
						</Typography>
						<Typography className={classes.elementsArchivingPaperParagraph}>
							Du er medlem av AD-gruppen (Active Directory) som gir tilgang til Elements, men det ble ikke funnet noen brukerkonto for din e-postadresse i Elements.
						</Typography>
						<Typography className={classes.elementsArchivingPaperParagraph}>
							Prøv igjen senere, eller meld inn sak via Selvbetjeningsportalen.
						</Typography>
					</Paper>
				)}

				{isUserInADGroup && isUserFoundInElements && (
					<>
						<Paper className={classes.elementsArchivingPaper} elevation={1}>
							<Typography variant="h6" component="h3">
								Arkiveres til Elements
							</Typography>
							<Typography className={classes.elementsArchivingPaperParagraph}>
								E-posten vil bli arkivert i sak- og arkivsystemet Sikri Elements.
							</Typography>
							<Typography className={classes.elementsArchivingPaperParagraph}>
								Du er innlogget i Elements med brukernavn: {elementsUsername}
							</Typography>
						</Paper>
					</>
				)}

				<div className={classes.expWrap}>
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
							{this.state.email
								? <Typography className={classes.heading}>E-postinfo</Typography>
								: <Typography>Laster...</Typography>
							}
							{this.state.email
								? <Typography className={classes.secondaryHeading}>Valgt e-post for
									arkivering</Typography>
								: null
							}
						</ExpansionPanelSummary>
						<ExpansionPanelDetails>
							<List>
								{
									this.state.email ?
									// loop over all objects in the caseKeyParsers list, and create a list item for each
									// For each object:
									//  if the object has a parse method: return the result of parse(this.state.case[object.key])
									//  else: return the value of this.state.case[object.key]
										emailParsers.map(parser => this.state.email[parser.key] ?
											<ListItem key={parser.key}>
												<ListItemText primary={[
													<Typography key={parser.key + 'Header'}
														style={{color: 'rgba(0, 0, 0, 0.54)'}}>{parser.name}</Typography>,
													parser.parse
														? parser.parse(this.state.email[parser.key])
														: this.state.email[parser.key]
												]}/>
											</ListItem> : null // skip if field has no value/null/undefined
										) : null // skip if this.state.email is not defined
								}
							</List>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</div>
    			<Typography color={'error'}>{this.state.errorMsg}</Typography>
    		</form>
    	)
    }
}

export default withStyles(styles)(Step1)
