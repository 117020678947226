import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from "@material-ui/core/TextField/TextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core";
import {isValidEmail} from "../util/personDataUtils";


const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    expWrap: {
        margin: 35
    },
    root: {
        display: 'flex',
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
        display: "flex"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectJP: {
        marginTop: theme.spacing.unit * 2,
    },
    flexContainer: {
        display: 'flex',
        flexDirection: "row",
        alignItems: "center"
    },
    flexGrow: {
        margin: "auto",
        flexGrow: 1
    },
    button: {
        marginRight: theme.spacing.unit,
    },
});

const caseKeyParsersElements = [
    {key: "saksnr", name: "Saksnummer", parse: o => o.saksaar + "/" + o.sakssekvensnummer},
    {key: "tittel", name: "Tittel"},
    {key: "saksstatus", name: "Saksstatus"},
    {key: "saksansvarligNavn", name: "Saksansvarlig"},
    {key: "adminEnhetNavn", name: "Administrativ enhet"},
    {key: "journalenhet", name: "Journalenhet"},
    {key: "arkivdel", name: "Arkivdel"},
    {key: "antallJournalposter", name: "Antall journalposter"},
];

class Step3 extends Component {
    constructor(props) {
        super(props);

        this.state = props.getStore();

        if (!this.state.step3EmailContactInitialized) {
            this.state.chosenStorageOption = null;
            this.state.expandedPlz = null;
            let jpType = "I";
            if (this.state.email.from.email === this.state.email.authenticatedUser) {
                jpType = "U";
            }
            let createJPPayload = {
                journalposttype: jpType,
                journalposttittel: this.state.email.subject,
                journalposttittelskjermet: "",
                epostdato: this.state.email.internalDate,
            };

            if (this.state.email.to)
                this.createContactsMap("to", this.state.email.to, createJPPayload);
            if (this.state.email.cc)
                this.createContactsMap("cc", this.state.email.cc, createJPPayload);
            if (this.state.email.bcc)
                this.createContactsMap("bcc", this.state.email.bcc, createJPPayload);
            if (this.state.email.from)
                this.createContactsMap("from", [this.state.email.from], createJPPayload);

            this.state.createJPPayload = createJPPayload;
            // this.setState({createJPPayload: payload});
            this.state.step3EmailContactInitialized = true;
        }

        if (!this.state.step3existingInitialized) {
            this.state.selectedJpSysid = "";
            this.state.step3existingInitialized = true;
        }
    }

    createContactsMap(fieldName, contacts, payload) {
        console.debug("contacts", contacts);
        if (!contacts.length) {
            return;
        }
        if (!payload[fieldName]) {
            payload[fieldName] = {}
        }
        contacts.forEach(contact => {
            if (!payload[fieldName][contact.email]) {
                let firstName = "";
                let surname = "";

                if (contact.name != null && contact.name !== "") {

                    if ((contact.name.charAt(0) === '"' && contact.name.charAt(contact.name.length - 1) === '"')
                        || (contact.name.charAt(0) === "'" && contact.name.charAt(contact.name.length - 1) === "'")) {
                        contact.name = contact.name.substr(1, contact.name.length - 2);
                    }

                    // Split on last space to find surname
                    let lastIndex = contact.name.lastIndexOf(' ');
                    if (lastIndex < 0) {
                        surname = contact.name;
                    } else {
                        // Add the string before the last .
                        // Return updated string, this will update the src attribute value
                        firstName = contact.name.substr(0, lastIndex);
                        surname = contact.name.substr(lastIndex + 1);
                    }
                }

                payload[fieldName][contact.email] = {
                    firstName: firstName,
                    surname: surname,
                    address1: "",
                    address2: "",
                    zip: undefined,
                    city: "",
                    email: contact.email
                }
            }
        });
    }

    componentDidMount() {
        this.props.onRef(this);
        this.setState({
            loading: "",
            errorMsg: ""
        });
        console.debug("COMPONENTDIDMOUNT CALLED");
        this.setState(this.props.getStore());
        if (!this.state.chosenStorageOption) {
            this.props.addDisableNext("chosenStorageOption");
        } else {
            this.props.removeDisableNext("chosenStorageOption");
        }
        if (this.state.chosenStorageOption === "quick-jp") {
            this.props.updateStore({buttonText: "til hurtigarkivering"});
        } else {
            this.props.updateStore({buttonText: ""});
        }
    }

    validate = async () => {
        if (!this.state.chosenStorageOption) {
            return "Ingen arkiveringsalternativ valgt"
        }

        // email validation
        let retVals = [];

        if (this.state.chosenStorageOption === "new-jp") {
            retVals.push(this.validateNewJP())
        } else if (this.state.chosenStorageOption === "quick-jp") {
            // do nothing
        }

        return retVals.join(". ");
    };

    validateNewJP = () => {
        let retVals = [];
        let contactsToCheck = [];
        if (this.state.createJPPayload.journalposttype === "U") {
            if(this.state.createJPPayload.to)
                contactsToCheck.push(this.state.createJPPayload.to);
            if(this.state.createJPPayload.cc)
                contactsToCheck.push(this.state.createJPPayload.cc);
            if(this.state.createJPPayload.bcc)
                contactsToCheck.push(this.state.createJPPayload.bcc);
        } else if (this.state.createJPPayload.journalposttype === "I") {
            contactsToCheck.push(this.state.createJPPayload.from);
        }

        console.debug("contactsToCheck", contactsToCheck);


        if (contactsToCheck.length > 0) {
            let invalidEmails = [];
            let contactWithNoEmailExists = false;
            contactsToCheck.forEach(contactDict => {
                Object.keys(contactDict).forEach(contactKey => {
                    let contact = contactDict[contactKey];
                    if (!contact.email) { // contact is invalid
                        contactWithNoEmailExists = true;
                    } else if (contact.email && !isValidEmail(contact.email)) {
                        console.debug("email valid check report", contact);
                        invalidEmails.push(contact.email);
                    }
                });
            });
            if (contactWithNoEmailExists) {
                retVals.push("Én eller flere korrespondanseparter mangler e-postadresse. Dette er et påkrevd felt.");
                this.props.updateStore({hideSupport: true});
            }

            console.debug("invalidemails", invalidEmails);

            if (invalidEmails.length === 1) {
                retVals.push(`${invalidEmails[0]} er ikke en gyldig e-post`);
                this.props.updateStore({hideSupport: true});
            } else if (invalidEmails.length > 1) {
                retVals.push(`Disse er ugyldige e-poster: ${invalidEmails.join(", ")}`);
                this.props.updateStore({hideSupport: true});
            }
        }
        return retVals;
    };

    createInputHandler(fieldName) { // must. resist. lambda. wrapping.
        return event => this.setState({[fieldName]: event.target.value});
    }

    createPayloadInputHandler(fieldName) {
        return event => {
            let payload = {...this.state.createJPPayload};
            payload[fieldName] = event.target.value;
            this.setState({createJPPayload: payload});
        };
    }

    handleExpansion = panel => (event, expanded) => {
        console.debug(panel);
        this.setState({
            expandedPlz: expanded ? panel : false,
        });
    };

    createCheckedHandler(name) {
        return event => {
            this.setState({[name]: event.target.checked});
        }
    }


    render() {
        const {classes} = this.props;

        return (
            <div>
                <Grid container justify="center">
                    <Typography variant="h6">
                        {
                            this.state.case.saksnr.saksaar +
                            "/" +
                            this.state.case.saksnr.sakssekvensnummer +
                            " - " +
                            this.state.case.tittel
                        }
                    </Typography>
                </Grid>
                <div className={classes.expWrap}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography className={classes.heading}>Saksinfo</Typography>
                            <Typography className={classes.secondaryHeading}>Detaljert info om valgt sak</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <List>
                                {
                                    // loop over all objects in the caseKeyParsers list, and create a list item for each
                                    // For each object:
                                    //  if the object has a parse method: return the result of
                                    //      parse(this.state.case[object.key])
                                    //  else: return the value of this.state.case[object.key]
                                    caseKeyParsersElements.map(parser =>
                                        <ListItem key={parser.key}>
                                            <ListItemText primary={[
                                                <Typography key={parser.key + "Header"}
                                                            style={{color: "rgba(0, 0, 0, 0.54)"}}>{parser.name}</Typography>,
                                                parser.parse
                                                    ? parser.parse(this.state.case[parser.key])
                                                    : this.state.case[parser.key]
                                            ]}/>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <Grid container justify="center">
                    <Typography variant="h6">Velg arkiveringsalternativ *</Typography>
                </Grid>
                <Grid>
                    <RadioGroup
                        aria-label="storage-option"
                        name="chosenStorageOption"
                        className={classes.group}
                        value={this.state.chosenStorageOption}
                        onChange={event => {
                            this.props.removeDisableNext("chosenStorageOption");
                            this.props.removeDisableNext("existingJPInvalid");
                            this.setState({chosenStorageOption: event.target.value});
                            if (event.target.value === "quick-jp") {
                                this.props.updateStore({buttonText: "til hurtigarkivering"});
                            } else {
                                this.props.updateStore({buttonText: ""});
                            }
                        }}
                    >
                        <FormControlLabel
                            value="new-jp"
                            control={<Radio color="primary"/>}
                            label="Lagre i ny journalpost"
                        />
                        <FormControlLabel
                            value="existing-jp"
                            control={<Radio color="primary"/>}
                            disabled // TODO re-implement later, if Sikri starts supporting archiving to an existing JP
                            label="Lagre i eksisterende journalpost"
                        />
                        <FormControlLabel
                            value="quick-jp"
                            control={<Radio color="primary"/>}
                            label="Hurtigarkivering"
                        />
                    </RadioGroup>
                </Grid>
                {this.state.chosenStorageOption
                    ? <Paper className={classes.paper}>
                        {this.state.chosenStorageOption === "new-jp"
                            ? this.renderNyJpForm()
                            : this.state.chosenStorageOption === "quick-jp"
                                ? Step3.renderQuickJPForm()
                                : null
                        }
                    </Paper>
                    : null
                }
            </div>
        )
    }

    createExpansionPanel(expandID, title, secondaryHeading, component) {
        const {classes} = this.props;
        const {expandedPlz} = this.state;
        return (
            <ExpansionPanel key={expandID}
                            expanded={expandedPlz === expandID}
                            onChange={this.handleExpansion(expandID)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography className={classes.heading}>{title}</Typography>
                    <Typography className={classes.secondaryHeading}>{secondaryHeading}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {component}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    renderNyJpForm() {
        const {classes} = this.props;
        return (
            <div>
                <Grid container justify="center">
                    <Typography variant="h6">Lagre i ny journalpost</Typography>
                </Grid>
                <form className={classes.container} noValidate autoComplete="off">
                    <Grid container className={classes.container} spacing={40}>
                        <Grid item key="title" xs={12} sm={6} className={classes.flexContainer}>
                            <TextField
                                id="title"
                                label="Tittel"
                                className={classes.flexGrow}
                                onChange={this.createPayloadInputHandler("journalposttittel")}
                                margin="normal"
                                onKeyPress={this.goNext}
                                helperText={" "} // alignment hack, you saw nothing
                                value={this.state.createJPPayload.journalposttittel}
                            />
                        </Grid>
                        
                        <Grid item container key="jp-type" xs={12} className={classes.flexContainer}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Journalposttype</FormLabel>
                                <RadioGroup
                                    justify="space-evenly"
                                    aria-label="jp-type"
                                    name="jp-type"
                                    value={this.state.createJPPayload.journalposttype}
                                    onChange={this.createPayloadInputHandler("journalposttype")}
                                >
                                    <FormControlLabel
                                        value="I"
                                        control={<Radio color="primary"/>}
                                        label="Inngående dokument (I)"
                                    />
                                    <FormControlLabel
                                        value="U"
                                        control={<Radio color="primary"/>}
                                        label="Utgående dokument (U)"
                                    />
                                    <FormControlLabel
                                        value="X"
                                        control={<Radio color="primary"/>}
                                        label="Internt dokument (X)"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {this.state.createJPPayload.journalposttype === "U"
                            ? this.renderReceiverData()
                            : this.state.createJPPayload.journalposttype === "I"
                                ? this.renderSenderData()
                                : null}
                    </Grid>
                </form>
            </div>
        )
    }

    renderReceiverData() {
        return [
            this.state.createJPPayload.to ? this.renderContactData(this.state.email.to, "to", "Mottakere") : null,
            this.state.createJPPayload.cc ? this.renderContactData(this.state.email.cc, "cc", "Kopimottakere") : null,
            this.state.createJPPayload.bcc ? this.renderContactData(this.state.email.bcc, "bcc", "Blindkopimottakere") : null
        ];
    }

    renderSenderData() {
        return this.renderContactData([this.state.email.from], "from", "Avsender");
    }

    createContactInputHandler(contactLocation, contact, contactFieldName) {
        return (event) => {
            let payload = {...this.state.createJPPayload};
            console.debug("payload", payload, contactLocation, contact.email, contactFieldName);
            payload[contactLocation][contact.email][contactFieldName] = event.target.value;
            this.setState({createJPPayload: payload});
            console.debug("current-ish createJPPayload", this.state.createJPPayload);
        }
    }

    renderContactData(contacts, fieldName, title) {
        const {classes} = this.props;
        return (
            <FormControl key={title + "/" + fieldName} className={[classes.flexGrow, classes.expWrap].join(", ")}>
                <FormLabel component="legend">{title}</FormLabel>
                {contacts.map(contact => {

                    return this.createExpansionPanel(
                        title + "/" + contact.email,
                        (this.state.createJPPayload[fieldName][contact.email].firstName) ?
                            this.state.createJPPayload[fieldName][contact.email].firstName + " " +
                            this.state.createJPPayload[fieldName][contact.email].surname :
                            this.state.createJPPayload[fieldName][contact.email].surname,
                        this.state.createJPPayload[fieldName][contact.email].email, (
                            <FormControl component="fieldset" key={title + "/" + contact.email}>
                                <Grid container className={classes.container}>
                                    <Grid item className={classes.flexContainer}
                                          key={title + "/" + fieldName + "/" + contact.email + "/email"} xs={12} sm={12}
                                          md={6}>
                                        <TextField
                                            className={classes.flexGrow}
                                            value={this.state.createJPPayload[fieldName][contact.email].email}
                                            onChange={this.createContactInputHandler(fieldName, contact, "email")}
                                            placeholder="E-postadresse"
                                            label="E-postadresse"
                                        />
                                    </Grid>
                                    <Grid item className={classes.flexContainer}
                                          key={title + "/" + fieldName + "/" + contact.email + "/firstName"} xs={12}
                                          sm={12} md={6}>
                                        <TextField
                                            className={classes.flexGrow}
                                            value={this.state.createJPPayload[fieldName][contact.email].firstName}
                                            onChange={this.createContactInputHandler(fieldName, contact, "firstName")}
                                            placeholder="Fornavn"
                                            label="Fornavn"
                                        />
                                    </Grid>
                                    <Grid item className={classes.flexContainer}
                                          key={title + "/" + fieldName + "/" + contact.email + "/surname"} xs={12}
                                          sm={12} md={6}>
                                        <TextField
                                            className={classes.flexGrow}
                                            value={this.state.createJPPayload[fieldName][contact.email].surname}
                                            onChange={this.createContactInputHandler(fieldName, contact, "surname")}
                                            placeholder="Etternavn/Firma"
                                            label="Etternavn/Firma"
                                        />
                                    </Grid>
                                    <Grid item className={classes.flexContainer}
                                          key={title + "/" + fieldName + "/" + contact.email + "/adr1"} xs={12} sm={12}
                                          md={6}>
                                        <TextField
                                            className={classes.flexGrow}
                                            value={this.state.createJPPayload[fieldName][contact.email].address1}
                                            onChange={this.createContactInputHandler(fieldName, contact, "address1")}
                                            placeholder="Adresselinje 1"
                                            label="Adresselinje 1"
                                        />
                                    </Grid>
                                    <Grid item className={classes.flexContainer}
                                          key={title + "/" + fieldName + "/" + contact.email + "/adr2"} xs={12} sm={12}
                                          md={6}>
                                        <TextField
                                            className={classes.flexGrow}
                                            value={this.state.createJPPayload[fieldName][contact.email].address2}
                                            onChange={this.createContactInputHandler(fieldName, contact, "address2")}
                                            placeholder="Adresselinje 2"
                                            label="Adresselinje 2"
                                        />
                                    </Grid>
                                    <Grid item className={classes.flexContainer}
                                          key={title + "/" + fieldName + "/" + contact.email + "/zip"} xs={12} sm={12}
                                          md={6}>
                                        <TextField
                                            className={classes.flexGrow}
                                            value={this.state.createJPPayload[fieldName][contact.email].zip}
                                            onChange={this.createContactInputHandler(fieldName, contact, "zip")}
                                            placeholder="Postnummer"
                                            label="Postnummer"
                                        />
                                    </Grid>
                                    <Grid item className={classes.flexContainer}
                                          key={title + "/" + fieldName + "/" + contact.email + "/city"} xs={12} sm={12}
                                          md={6}>
                                        <TextField
                                            className={classes.flexGrow}
                                            value={this.state.createJPPayload[fieldName][contact.email].city}
                                            onChange={this.createContactInputHandler(fieldName, contact, "city")}
                                            placeholder="Poststed"
                                            label="Poststed"
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        )
                    )

                })}
            </FormControl>
        );
    }

    createRecieverInputField(keyPrefix, contact, fieldName, fieldNameHumanReadable) {
        const {classes} = this.props;
        return (
            <Grid item className={classes.flexContainer} key={keyPrefix + "/" + contact.email + "/" + fieldName}
                  xs={12} sm={12} md={6}>
                <TextField
                    className={classes.flexGrow}
                    value={this.state.createJPPayload[fieldName][contact.email][fieldName]}
                    onChange={this.createContactInputHandler(fieldName, contact, fieldName)}
                    placeholder={fieldNameHumanReadable}
                    label={fieldNameHumanReadable}
                />
            </Grid>
        );
    }

    static renderQuickJPForm() {
        return (
            <Typography>
                Arkiverer til en ny journalpost med standardinnstillinger for journalpost og vedlegg.
            </Typography>
        )
    }
}

export default withStyles(styles)(Step3);
