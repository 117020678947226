import React, {Component} from 'react';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import List from "@material-ui/core/List/List";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';


const contactToText = contact => {
    if (contact.surname) {
        if (contact.firstName) {
            return `${contact.firstName} ${contact.surname}`
        }
        return contact.surname
    } else {
        return contact.email
    }
};


const contactDictToText = (contactDict, header) => {
    return [
        <Typography key="attachmentsHeader"
                    style={{color: "rgba(0, 0, 0, 0.54)"}}>{header}</Typography>,
        Object.keys(contactDict).map(
            contactKey => contactDict[contactKey]
        ).map(
            contactToText
        ).join(
            ", "
        )
    ];
};

const jpStatusTextLookup = {
    I: "Inngående dokument (I)",
    U: "Utgående dokument (U)",
    X: "Internt dokument (X)"
};

export default class Step5 extends Component {
    componentDidMount() {
        this.props.onRef(this);
        this.props.updateStore({buttonText: "Arkivér"})
    }

    validate = async () => {
    };


    renderRecipients(store) {
        return ([
            (store.createJPPayload.to && Object.keys(store.createJPPayload.to).length) ?
            <ListItem key="mottakere">
                <ListItemText primary={contactDictToText(store.createJPPayload.to, "Mottakere")}/>
            </ListItem> : null,
            (store.createJPPayload.cc && Object.keys(store.createJPPayload.cc).length) ?
            <ListItem key="kopimottakere">
                <ListItemText primary={contactDictToText(store.createJPPayload.cc, "Kopimottakere")}/>
            </ListItem> : null,
            (store.createJPPayload.bcc && Object.keys(store.createJPPayload.bcc).length) ?
            <ListItem key="blindkopimottakere">
                <ListItemText primary={contactDictToText(store.createJPPayload.bcc, "Blindkopimottakere")}/>
            </ListItem> : null
        ]);
    }

    renderSender(store) {
        return (
            <ListItem key="avsender">
                <ListItemText
                    primary={contactDictToText(store.createJPPayload.from, "Avsender")}/>
            </ListItem>
        )
    }

    buildAttachmentTypography(atc, store) {
        return (<Typography
            key={atc.id}
            style={{fontStyle: store.mainDocId === atc.id ? "italic" : "normal"}}>
            {`${atc.filename}.${atc.fileExtension}`}
        </Typography>);
    }

    render() {
        const store = this.props.getStore();
        return (
            <div>
                <List>
                    <ListItem key="arkivsak">
                        <ListItemText
                            primary={[
                                <Typography key="arkivsakHeader"
                                            style={{color: "rgba(0, 0, 0, 0.54)"}}>Arkivsak</Typography>,
                                <Typography
                                    key="arkivsakValue">{`${store.case.saksnr.saksaar}/${store.case.saksnr.sakssekvensnummer} ${store.case.tittel}`}</Typography>
                            ]}/>
                    </ListItem>
                    {(store.chosenStorageOption !== 'existing-jp') ?
                        [<ListItem key="journalposttittel">
                            <ListItemText
                                primary={[
                                    <Typography key="jpTitleHeader"
                                                style={{color: "rgba(0, 0, 0, 0.54)"}}>Journalposttittel</Typography>,
                                    <Typography key="jpTitleValue">{store.createJPPayload.journalposttittel}</Typography>
                                ]}/>
                        </ListItem>,
                        <ListItem key="journalposttype">
                            <ListItemText
                                primary={[
                                    <Typography key="jpTypeHeader"
                                                style={{color: "rgba(0, 0, 0, 0.54)"}}>Journalposttype</Typography>,
                                    <Typography
                                        key="jpTypeValue">{jpStatusTextLookup[store.createJPPayload.journalposttype]}</Typography>
                                ]}/>
                        </ListItem>]

                        : <ListItem key="journalpost">
                            <ListItemText
                                primary={[
                                    <Typography key="jpHeader"
                                                style={{color: "rgba(0, 0, 0, 0.54)"}}>Journalpost</Typography>,
                                    <Typography
                                        key="jpValue">{store.selectedJp.number}: '{store.selectedJp.title}'</Typography>
                                ]}/>
                        </ListItem>}

                    {store.chosenStorageOption !== 'existing-jp' && store.createJPPayload.journalposttype === "U"
                        ? this.renderRecipients(store)
                        : store.chosenStorageOption !== 'existing-jp' && store.createJPPayload.journalposttype === "I"
                            ? this.renderSender(store)
                            : null}
                    <ListItem>
                        <ListItemText
                            primary={[
                                <Typography key="attachmentsHeader"
                                            style={{color: "rgba(0, 0, 0, 0.54)"}}>Vedlegg</Typography>,
                                Object.keys(store.attachmentsPayload).map(
                                    atchKey => store.attachmentsPayload[atchKey]
                                ).filter(
                                    atc => atc.selected
                                ).map(
                                    atc =>
                                        (store.mainDocId === atc.id) ? <Tooltip
                                            key="mainAttachmentTooltip"
                                            title={`${atc.filename}.${atc.fileExtension} er hoveddokument`}
                                            placement="top-start">
                                            {this.buildAttachmentTypography(atc, store)}
                                        </Tooltip> : this.buildAttachmentTypography(atc, store)
                                )
                            ]}
                        />
                    </ListItem>
                </List>
            </div>
        )
    }
}
