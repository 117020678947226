import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {generateMailtoLink, getNameAndEmailHtml} from "../util/personDataUtils";
import Typography from "@material-ui/core/Typography/Typography";
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {Base64} from 'js-base64';

const styles = theme => ({});

class Step6 extends Component {
    validate = async () => {
        return this.doArchive();
    };
    doArchive = async () => {
        this.setState({
            jp_created_error: false,
            attachmentsPostComplete_error: false,
            labelSetComplete_error: false,
        });
        let retVals = [];

        await this.createJPAndDocsInElements(retVals);

        if (this.state.attachmentsPostComplete && !this.state.labelSetComplete) {
            const setLabelRequest = {
                "messageId": this.state.messageId,
                "labelName": "Arkivert i Elements",
                "senderAndRecipients": this.state.senderAndRecipients.split(",")
            };

            this.props.addLoading("label");
            await fetch(`/gap/gmail/label`, {
                method: 'PUT',
                body: JSON.stringify(setLabelRequest)
            })
                .then(res => {
                    if (res.ok) {
                        return res;
                    } else {
                        throw res;
                    }
                })
                .then(() =>
                    this.setState({labelSetComplete: true})
                )
                .catch(() => {
                    this.setState({labelSetComplete_error: true});
                    retVals.push("Feil oppsto under setting av etikett på e-post i Gmail")
                })
                .finally(() => this.props.removeLoading("label"));
        }

        let archiveStatus = retVals.join(". ");

        this.setState({
            archiveStatus: archiveStatus,
            archivingAttempted: true
        });
        if (archiveStatus) {
            this.props.updateStore({buttonText: "Prøv igjen"})
        } else {
            this.props.updateStore({buttonText: "Lukk siden"})
        }

        return archiveStatus;
    };

    constructor(props) {
        super(props);
        this.state = props.getStore();
    }

    // Builds the person data string for the REST request with name, email, and address data
    static buildPersonDataObj(personData) {
        if (personData == null) return null;
        let res = {};
        res.fornavn = personData.firstName;
        res.etternavn = (personData.surname === "") ? personData.email : personData.surname;
        res.epost = personData.email;
        if (personData.address1 !== "") res.adresse1 = personData.address1;
        if (personData.address2 !== "") res.adresse2 = personData.address2;

        const zip = (personData.zip != null) ? personData.zip.replace(/\D/g, '') : ""; // Remove non-numeric characters
        if (zip !== "") res.postnr = zip;

        if (personData.city !== "") res.poststed = personData.city;

        return res;
    }

    async createJPAndDocsInElements(retVals) {
        if (this.state.jp_created) {
            return
        }
        const newJpRequest = this.buildNewJpRequest(this.state, true);
        console.debug(newJpRequest);

        this.props.addLoading("creatingJP");
        await fetch(`/gap/elements/jp?gmailmsgid=${this.state.messageId}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(newJpRequest)
        }).then(res => {
            console.debug("newJpRequest RAW!", res);
            if (res.ok) {
                return res;
            } else {
                throw res
            }
        }).then(res =>
            res.json()
        ).then(bod => {
            if (bod && bod.journalpostnr) {
                console.debug("JP created!", bod);

                // Expected format: "2023/217-21". Need to extract the number after the dash
                if (bod.journalpostnr.split("-").length !== 2) {
                    throw new Error("Ugyldig journalpostnummer fra Elements: " + bod.journalpostnr);
                }
                const jpNr = bod.journalpostnr.split("-")[1];

                this.setState({
                    selectedJpNr: jpNr,
                    jp_created: true,
                    attachmentsPostComplete: true
                });
            } else {
                throw bod;
            }
        }).catch(res => {
            this.setState({jp_created_error: true});
            console.error(res);

            if (res.json) {
                res.json().then(respBodyJson => {
                    if (respBodyJson && respBodyJson.error && respBodyJson.error.trim() !== "") {
                        this.props.updateStore({elementsErrorMsg: respBodyJson.error});
                    }
                });
            }

            retVals.push("Det har oppstått en feil under opprettelse av ny journalpost i Elements");
        }).finally(() => this.props.removeLoading("creatingJP"));
    }

    // Creates a json request body to send to TK-sak.
    buildNewJpRequest(state, includeAttachments) {
        let newJpRequest = {};

        let jpType = state.createJPPayload.journalposttype;

        newJpRequest.saksnummer = {
            saksaar: state.case.saksnr.saksaar,
            sakssekvensnummer: state.case.saksnr.sakssekvensnummer
        };

        newJpRequest.tilgangsgruppe = state.case.tilgangsgruppeNavn;
        newJpRequest.journalposttype = jpType;
        newJpRequest.journalposttittel = state.createJPPayload.journalposttittel;
        newJpRequest.journalposttittelskjermet = state.createJPPayload.journalposttittelskjermet;
        newJpRequest.skjerming = state.case.skjerming

        newJpRequest.epostdato = state.email.internalDate;

        // -- Sender/recipients --
        if (jpType === "I") {
            let from = state.createJPPayload.from;
            for (let key in from) {
                newJpRequest.avsender = Step6.buildPersonDataObj(state.createJPPayload.from[key]);
            }
        } else if (jpType === "U") {
            const toRecipients = state.createJPPayload.to;
            if (toRecipients != null) {
                newJpRequest.mottakere = [];
                for (let key in toRecipients) {
                    newJpRequest.mottakere.push(Step6.buildPersonDataObj(toRecipients[key]));
                }
            }

            const ccRecipients = state.createJPPayload.cc;
            if (ccRecipients != null) {
                newJpRequest.kopimottakere = [];
                for (let key in ccRecipients) {
                    newJpRequest.kopimottakere.push(Step6.buildPersonDataObj(ccRecipients[key]));
                }
            }

            const bccRecipients = state.createJPPayload.bcc;
            if (bccRecipients != null) {
                if (newJpRequest.kopimottakere == null) newJpRequest.kopimottakere = [];
                for (let key in bccRecipients) {
                    newJpRequest.kopimottakere.push(Step6.buildPersonDataObj(bccRecipients[key]));
                }
            }
        }

        if (includeAttachments) {
            const atcReq = this.buildPostAttachmentsRequest(state);
            newJpRequest.dokumentliste = atcReq.dokumentliste;
            newJpRequest.senderAndRecipients = atcReq.senderAndRecipients;
        }
        return newJpRequest;
    }

    // Creates a json request body for sending attachments to TK-sak
    buildPostAttachmentsRequest(state) {
        const attachments = state.attachmentsPayload;
        let docList = [];

        for (let attachmentKey in attachments) {
            const attachment = attachments[attachmentKey];
            if (attachment.selected) {
                let doc = {};

                doc.base64String = attachment.body;
                doc.tittel = attachment.filename + "." + attachment.fileExtension;
                doc.filnavn = attachment.filename + "." + attachment.fileExtension;
                doc.docStatusCode = attachment.status;
                doc.mimeType = attachment.mimeType;
                doc.type = (attachment.id === state.mainDocId) ? "H" : "V";

                if (attachment.isEmailBody) {
                    if (state.writeMetadata == null || state.writeMetadata) { // Treat undefined/null value as true
                        const metadataHeader = this.generateMetadataHeader(state);
                        doc.metadataHeader = Base64.encode(metadataHeader);
                    }
                } else {
                    doc.attachmentId = attachment.id;
                }
                docList.push(doc);
            }
        }

        return {
            systemID: state.selectedJpSysid,
            dokumentliste: docList,
            senderAndRecipients: this.state.senderAndRecipients
        };
    }

    // Returns html string with metadata about the email: from, to, cc, date, subject, attachments
    generateMetadataHeader(state) {
        const email = state.email;
        const date = new Date(email.internalDate);

        if (date != null && !(isNaN(date.getTime())) && email.subject != null) {

            let res = "<!-- Metadata line generated by TK-sak archive service for Gmail. -->";

            // Add sender
            let senderStr;
            if (state.createJPPayload != null && state.createJPPayload.from != null) {
                for (let senderKey in state.createJPPayload.from) {
                    const sender = state.createJPPayload.from[senderKey];
                    senderStr = getNameAndEmailHtml(sender);
                }
            } else {
                const sender = email.from;
                const senderMailto = generateMailtoLink(sender.email);
                senderStr = (sender.name != null && sender.name.trim() !== "") ?
                    sender.name + " &lt;" + senderMailto + "&gt;" : senderMailto;
            }
            res += "<b>Fra: </b>" + senderStr + "<br>";

            // Add recipients
            if (state.createJPPayload != null && state.createJPPayload.to != null) {

                if (Object.keys(state.createJPPayload.to).length > 0) {
                    let toRecipientsStr = "";
                    for (let toRecipientKey in state.createJPPayload.to) {
                        if (toRecipientsStr !== "") toRecipientsStr += ", ";
                        toRecipientsStr += getNameAndEmailHtml(state.createJPPayload.to[toRecipientKey]);
                    }
                    res += "<b>Til: </b>" + toRecipientsStr + "<br>";
                }
            } else {
                if (email.to != null && email.to.length > 0) {
                    let toRecipientsStr = "";
                    for (let i = 0; i < email.to.length; i++) {
                        if (i > 0) toRecipientsStr += ", ";
                        const toRecipientMailto = generateMailtoLink(email.to[i].email);
                        toRecipientsStr += (email.to[i].name != null && email.to[i].name.trim() !== "") ?
                            email.to[i].name + " &lt;" + toRecipientMailto + "&gt;" : toRecipientMailto;
                    }
                    res += "<b>Til: </b>" + toRecipientsStr + "<br>";
                }
            }

            // Add CC recipients
            if (state.createJPPayload != null && state.createJPPayload.cc != null) {
                if (Object.keys(state.createJPPayload.cc).length > 0) {
                    let ccRecipientsStr = "";
                    for (let ccRecipientKey in state.createJPPayload.cc) {
                        if (ccRecipientsStr !== "") ccRecipientsStr += ", ";
                        ccRecipientsStr += getNameAndEmailHtml(state.createJPPayload.cc[ccRecipientKey]);
                    }
                    res += "<b>Kopi: </b>" + ccRecipientsStr + "<br>";
                }
            } else {
                if (email.cc != null && email.cc.length > 0) {

                    let ccRecipientsStr = "";
                    for (let i = 0; i < email.cc.length; i++) {
                        if (i > 0) ccRecipientsStr += ", ";
                        const ccRecipientMailto = generateMailtoLink(email.cc[i].email);
                        ccRecipientsStr += (email.cc[i].name != null && email.cc[i].name.trim() !== "") ?
                            email.cc[i].name + " &lt;" + ccRecipientMailto + "&gt;" : ccRecipientMailto;
                    }
                    res += "<b>Kopi: </b>" + ccRecipientsStr + "<br>";
                }
            }

            // Add BCC recipients
            if (state.createJPPayload != null && state.createJPPayload.bcc != null) {
                if (Object.keys(state.createJPPayload.bcc).length > 0) {
                    let bccRecipientsStr = "";
                    for (let bccRecipientKey in state.createJPPayload.bcc) {
                        if (bccRecipientsStr !== "") bccRecipientsStr += ", ";
                        bccRecipientsStr += getNameAndEmailHtml(state.createJPPayload.bcc[bccRecipientKey]);
                    }
                    res += "<b>Blindkopi: </b>" + bccRecipientsStr + "<br>";
                }
            } else {
                if (email.bcc != null && email.bcc.length > 0) {

                    let bccRecipientsStr = "";
                    for (let i = 0; i < email.cc.length; i++) {
                        if (i > 0) bccRecipientsStr += ", ";
                        const bccRecipientMailto = generateMailtoLink(email.bcc[i].email);
                        bccRecipientsStr += (email.bcc[i].name != null && email.bcc[i].name.trim() !== "") ?
                            email.bcc[i].name + " &lt;" + bccRecipientMailto + "&gt;" : bccRecipientMailto;
                    }
                    res += "<b>Blindkopi: </b>" + bccRecipientsStr + "<br>";
                }
            }

            // Add date sent
            const hours = ('0' + date.getHours()).slice(-2); // Add leading zero if < 10
            const minutes = ('0' + date.getMinutes()).slice(-2); // Add leading zero if < 10
            const monthNamesNorwegian = ['januar', 'februar', 'mars', 'april', 'mai', 'juni',
                'juli', 'august', 'september', 'oktober', 'november', 'desember'];

            res += "<b>Sendt: </b>" + date.getDate() + ". " +
                monthNamesNorwegian[date.getMonth()] + " " + date.getFullYear() +
                " kl. " + hours + "." + minutes + "<br>";

            // Add subject
            res += "<b>Emne: </b>" + this.xmlEscaping(email.subject) + "<br>";

            // Append list of attachments
            const attachments = email.attachments;
            if (attachments != null && attachments.length > 0) {
                let attachmentFileNames = "";
                for (let i = 0; i < attachments.length; i++) {
                    const attachment = attachments[i];

                    // Only list attachment if it was selected for archiving
                    if (state.attachmentsPayload[attachment.id] != null && state.attachmentsPayload[attachment.id].selected) {
                        if (attachmentFileNames.length > 0) attachmentFileNames += "; ";
                        attachmentFileNames += attachment.filename;
                    }
                }
                if (attachmentFileNames.length > 0) {
                    res += "<b>Vedlegg: </b>" + attachmentFileNames + "<br>";
                }
            }
            res += "</div><br>";
            res = res.replace(/[\u2013\u2014]/g, "-");

            return res;
        }
        console.error("Error occurred in generateMetadataHeader()");
        return "";
    }

    xmlEscaping(str) {
        if (str == null) return null;
        str = str.replace("&", "&amp;");
        str = str.replace("<", "&lt;");
        str = str.replace(">", "&gt;");
        return str;
    }

    componentDidMount() {
        this.props.onRef(this);
        this.props.handleNext();
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div>
                {this.state.jp_created && this.state.attachmentsPostComplete && this.state.labelSetComplete
                    ? (
                        <Typography>
                            Arkivering utført. Du kan nå lukke dnne siden.
                        </Typography>
                    ) : (
                        <Typography>
                            Arkiverer...
                        </Typography>
                    )
                }
                <List>

                    {this.state.chosenStorageOption !== "existing-jp" && (
                        <LoadingStepComp done={this.state.jp_created}
                                         error={this.state.jp_created_error}
                                         finishedText={"Journalpost opprettet"}
                                         loadingText={"Oppretter journalpost..."}/>
                    )}
                    <LoadingStepComp done={this.state.attachmentsPostComplete}
                                     error={this.state.attachmentsPostComplete_error}
                                     finishedText={"Vedlegg lagret"}
                                     loadingText={"Lagrer vedlegg..."}/>
                    <LoadingStepComp done={this.state.labelSetComplete}
                                     error={this.state.labelSetComplete_error}
                                     finishedText={"Etikett lagt til"}
                                     loadingText={"Legger til etikett på e-post..."}/>
                </List>
            </div>
        )
    }
}

const loadingStepCompStyles = {
    success: {
        color: '#00d600',
    },
    icon: {
        height: '2rem',
        width: '2rem',
    }
};

const LoadingStepComp = withStyles(loadingStepCompStyles)(({classes, done, error, finishedText, loadingText}) => (
    <ListItem>
        <ListItemIcon classes={classes.iconContainer}>
            {error
                ? (<ErrorOutline color='error' className={classes.icon}/>)
                : (done
                        ? (<CheckCircleOutline className={[classes.success, classes.icon].join(" ")}/>)
                        : (<HourglassEmpty className={classes.icon}/>)
                )
            }
        </ListItemIcon>
        <ListItemText
            primary={done ? finishedText : loadingText}
        />
    </ListItem>
));

export default withStyles(styles)(Step6);